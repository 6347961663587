.redirects{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    .page-content{
        position: relative;
        display: flex;
        flex-flow: column;
        flex: 1;
        padding: 1rem;
        background-color: #ffffff;
        border-radius: .6rem;
        box-shadow: 0 0 .6rem rgba(0,0,0,.06);
        .redirect-item{
            margin-bottom: 1rem;
            display: flex;
            .ant-input-group-wrapper{
                margin-right: 1rem;
            }
        }
    }
}