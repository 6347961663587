.scheme-list{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    .page-content{
        position: relative;
        display: flex;
        flex-flow: column;
        flex: 1;
        padding: 1rem;
        background-color: #ffffff;
        border-radius: .6rem;
        box-shadow: 0 0 .6rem rgba(0,0,0,.06);
    }
}
.scheme-edit-area{
    .scheme-info{
        display: flex;
        flex-flow: column;
        margin-bottom: 1rem;
        .ant-input-group-wrapper{
            margin: .5rem 0;
            &:first-child{
                margin-top: 0;
            }
        }
        textarea{
            margin: .5rem 0;
        }
    }
    .scheme-content-box{
        border: 1px solid #dddddd;
        padding: 0 .5rem .5rem .5rem;
        margin-bottom: 1rem;
        .toolbar{
            height: 2.8rem;
            border-bottom: 1px dashed #dddddd;
            margin-bottom: .5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            h5{
                margin-bottom: 0;
            }
            .ant-select, button{
                margin-left: .5rem;
            }
        }
    }
    .append-area{
        border-top: 1px dashed #dddddd;
        padding-top: .5rem;
        button{
            margin-right: .5rem;
        }
    }
    .box-and-components{
        padding: .5rem 0;
    }
}