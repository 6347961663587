.gallery-page{
    display: flex;
    flex-flow: column;
    flex: 1;
    .image-list{
        border-radius: .6rem;
        box-shadow: 0 0 .6rem rgba(0,0,0,.06);
        flex: 1;
        background-color: #ffffff;
        border: 1px solid rgb(235, 237, 240);
        padding: 1rem;
        display: flex;
        flex-flow: column;
        .list{
            flex: 1;
            position: relative;
            .ant-upload-picture-card-wrapper{
                width: 100%;
                height: 100%;
                .ant-upload-list.ant-upload-list-picture-card{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    .ant-upload-list-picture-card-container{
                        margin: 0 25px 8px 25px;
                    }
                }
            }
            .list-loading{
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #ffffff;
                z-index: 9;
            }
        }
        .pagination{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}