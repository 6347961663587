.component-edit-area{
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
    .info{
        width: calc(min(100%,50rem));
        border-bottom: 2px dashed #dddddd;
        margin-bottom: 1rem;
        padding-bottom: .5rem;
        display: flex;
        flex-flow: column;
        .ant-input-group-wrapper{
            margin-bottom: .4rem;
        }
    }
    .component{
        width: calc(min(100%,50rem));
        .component-header{
            width: 100%;
            display: flex;
            align-items: center;
            padding-bottom: .6rem;
            margin-bottom: .6rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            button{
                margin-right: .5rem;
            }
        }
        input, textarea{
            margin-bottom: .5rem;
        }
        textarea{
            resize: none;
        }
        .checkbox-div{
            display: flex;
            flex-flow: column;
            label{
                margin-left: 0;
            }
        }
        button{
            display: flex;
            align-items: center;
            justify-content: center;
            .color{
                box-shadow: 0 0 8px rgba(0, 0, 0, .15);
                width: 1rem;
                height: 1rem;
                border-radius: 1rem;
                margin-right: 0.5rem;
            }
        }
        .component-body{
            .list-header-div{
                display: flex;
                flex-flow: column;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);
                margin-bottom: .5rem;
                .in{
                    margin-bottom: .5rem;
                    width: 100%;
                    display: flex;
                    flex-flow: row;
                    justify-content: center;
                    align-items: flex-start;
                    .ant-picker{
                        height: 31.6px;
                        margin-right: .5rem;
                        input{
                            margin-bottom: 0;
                        }
                    }
                    button{
                        margin-left: .5rem;
                    }
                }
            }
        }
    }
    .null{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.ant-modal-body .component-edit-area {
    margin-top: 0;
}
.scheme-area{
    margin-top: 0;
}