.settings{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    .page-content{
        position: relative;
        display: flex;
        flex-flow: column;
        flex: 1;
        padding: 1rem;
        background-color: #ffffff;
        border-radius: .6rem;
        box-shadow: 0 0 .6rem rgba(0,0,0,.06);
        .social-media{
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            .ant-input-group-wrapper{
                margin: .2rem;
                max-width: 18rem;
            }
        }
        .page-menu{
            margin-bottom: 1rem;
            .menu-item{
                width: 35rem;
                margin-bottom: .5rem;
                border-bottom: 1px solid #dddddd;
                .toolbar{
                    display: flex;
                    margin-bottom: .5rem;
                    button{
                        margin-left: .5rem;
                    }
                }
                .ant-input-group-wrapper{
                    margin-bottom: .5rem;
                }
                &.type-cascader-item{
                    width: 32rem;
                    margin-left: 3rem;
                }
            }
        }
        .contact{
            margin-bottom: 1rem;
            display: flex;
            flex-flow: column;
            .ant-input-group-wrapper,
            textarea{
                margin: .2rem;
                max-width: 30rem;
            }
        }
    }
}