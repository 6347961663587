.page-components-creator{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    .page-content {
        display: flex;
        flex-flow: column;
        flex: 1;
        padding: 1rem;
        background-color: #ffffff;
        border-radius: .6rem;
        box-shadow: 0 0 .6rem rgba(0, 0, 0, .06);
        .page-components-header {
            display: flex;
            flex-flow: row-reverse;
            justify-content: center;
            align-items: center;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            button, .ant-select{
                margin-right: .5rem;
            }
        }
    }
}