.statistics{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    .page-content{
        position: relative;
        display: flex;
        flex-flow: column;
        flex: 1;
        .statistics-header{
            display: flex;
            .box{
                flex: 1;
                margin-right: 1rem;
                width: fit-content;
                height: 8rem;
                background-color: #ffffff;
                border-radius: .6rem;
                box-shadow: 0 0 .6rem rgba(0,0,0,.06);
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 1rem 1.5rem;
                .icon{
                    width: 4rem;
                    height: 4rem;
                    border-radius: 3rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 2rem;
                    margin-right: 1rem;
                }
                .box-content{
                    .number{
                        font-size: 1.8rem;
                        font-weight: 700;
                        .animated{
                            margin-right: .3rem;
                        }
                        .text{
                            font-size: 1.2rem;
                            margin-right: .3rem;
                        }
                    }
                    .title{
                        font-size: .7rem;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
            .real-time{
                width: 21rem;
                flex: 0 1 auto;
                .icon{
                    background-color: rgba(243, 156, 18, .2);
                    color: rgb(243, 156, 18);
                }
            }
            .view{
                .icon{
                    background-color: rgba(231, 76, 60, .2);
                    color: rgb(231, 76, 60);
                }
            }
            .user{
                .icon{
                    background-color: rgba(46, 204, 113 , .2);
                    color: rgb(46, 204, 113);
                }
            }
            .session-time{
                .icon{
                    background-color: rgba(142, 68, 173, .2);
                    color: rgb(142, 68, 173);
                }
            }
        }
        .statistics-body{
            .ant-table-wrapper{
                width: 100%;
                background-color: #ffffff;
                border-radius: .6rem;
                box-shadow: 0 0 .6rem rgba(0,0,0,.06);
                padding: 1rem 1rem 0 1rem;
                margin-top: 1rem;
            }
            .charts{
                width: 100%;
                height: 25rem;
                background-color: #ffffff;
                border-radius: .6rem;
                box-shadow: 0 0 .6rem rgba(0,0,0,.06);
                margin-top: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 1rem;
                .pie{
                    flex: 1;
                    padding: 1rem 0;
                    height: 100%;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;
                    .pie-title{
                        width: 100%;
                        margin-left: 2rem;
                        font-size: 1.5rem;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}