#root{
    width: 100%;
    .app{
        width: 100%;
        min-height: 100vh;
        display: flex;
        padding-left: 256px;
        .navigation-menu{
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            flex-flow: column;
            width: 256px;
            height: 100vh;
            overflow: auto;
            .navigation-header,
            .navigation-footer{
                border-right: 1px solid #f0f0f0;
            }
            .menu{
                width: 100%;
            }
            .navigation-header{
                width: 100%;
                padding: 2rem 0;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 80%;
                }
            }
            .navigation-footer{
                width: 100%;
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                padding: 1.5rem;
                text-align: center;
            }
        }
        .content{
            flex: 1;
            background-color: #f5f5f5;
            width: 100%;
            padding: 1rem;
            display: flex;
            flex-flow: column;
            .page-header{
                background-color: #ffffff;
                border-radius: .6rem;
                box-shadow: 0 0 .6rem rgba(0,0,0,.06);
                margin-bottom: 1rem;
                .ant-page-header-heading-title{
                    font-size: 1.6rem;
                    font-weight: 400!important;
                }
            }
        }
    }
}
.image-selector-image-list{
    .image-list{
        overflow: auto;
        height: calc(25rem - 56px);
        padding: 1rem;
        display: flex;
        flex-flow: column;
        .list{
            flex: 1;
            position: relative;
            .ant-upload-picture-card-wrapper{
                width: 100%;
                height: 100%;
                .ant-upload-list.ant-upload-list-picture-card{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    .ant-upload-list-picture-card-container{
                        margin: 0 8px 8px 0;
                    }
                    .ant-upload-list-item-actions{
                        display: block;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                        button{
                            display: none;
                        }
                        a{
                            display: block;
                            width: 100%;
                            height: 100%;
                            svg{
                                display: none;
                            }
                        }
                    }
                }
            }
            .list-loading{
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #ffffff;
                z-index: 9;
            }
        }
        .pagination{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.image-selector-dragger{
    height: calc(25rem - 56px);
    padding: 1rem;
    .real-dragger{
        padding: 1rem;
    }
}
#root .app .navigation-menu,
.image-selector-image-list .image-list,
.append-form .page-content .form-content,
.form-preview .form-preview-in{
    &::-webkit-scrollbar{
        width: 2px;
    }
}
#root .app .navigation-menu,
.image-selector-image-list .image-list,
.append-form .page-content .form-content,
.form-preview .form-preview-in{
    &::-webkit-scrollbar-track{
        background: transparent;
    }
}
#root .app .navigation-menu,
.image-selector-image-list .image-list,
.append-form .page-content .form-content,
.form-preview .form-preview-in{
    &::-webkit-scrollbar-thumb{
        background: #dddddd;
    }
}
.ck.ck-splitbutton.ck-dropdown__button{
    display: flex;
}
.ck-editor__editable_inline {
    min-height: 20rem;
}
textarea{
    resize: none;
}
h4, h5{
    font-weight: 400!important;
}
.ant-message{
    z-index: 10000;
}
.select-image-button{
    position: fixed;
    bottom: 0;
    right: 1.5rem;
    border-radius: 1rem 1rem 0 0;
    z-index: 9998
}